import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dictList: [],
  total: 0,
  visibleAdd: false,
  selectedData: null,
};

const dictSlice = createSlice({
  name: "dict",
  initialState: initialState,
  reducers: {
    setDict: (state, action) => {
      state.dictList = [...action.payload.data];
      state.total = action.payload.total;
    },
    addDictItem: (state, action) => {
      const tmp = [...state.dictList, ...action.payload];
      state.dictList = tmp;
      state.total += 1;
    },
    removeDictItem: (state, action) => {
      const key = action.payload.key;
      const tmp = [...state.dictList];
      const idx = tmp.findIndex((item) => item._id === key);
      if (idx >= 0) {
        tmp.splice(idx, 1);
        state.dictList = tmp;
        state.total -= 1;
      }
    },
    setVisibleAdd: (state, action) => {
      state.visibleAdd = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
  },
});

export const dictAction = dictSlice.actions;

export default dictSlice.reducer;
