import { configureStore } from "@reduxjs/toolkit";
import discoveryReducer from "./discoverySlice";
import metricReducer from "./metricsSlice";
import agentReducer from "./agentManagementSlice";
import dashboardSlice from "./dashboardSlice";
import tagSlice from "./tagSlice";
import callSlice from "./callSlice";
import dialogSlice from "./dialogSlice";
import dictSlide from "./dictSlide";
import metricCreateSlide from "./metricCreateSlide";
import keywordSlice from "./keywordSlice";

const store = configureStore({
  reducer: {
    discovery: discoveryReducer,
    metric: metricReducer,
    agent: agentReducer,
    dashboard: dashboardSlice,
    tag: tagSlice,
    call: callSlice,
    dialog: dialogSlice,
    dict: dictSlide,
    metricCreate: metricCreateSlide,
    keyword: keywordSlice,
  },
});

export default store;
