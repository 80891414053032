import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { MdOutlineSpaceDashboard, MdOutlineSupportAgent } from "react-icons/md";
import {
  BiTask,
  BiPhoneCall,
  BiPurchaseTagAlt,
  BiSearch,
  BiLineChart,
  BiUser,
  BiBookAdd,
} from "react-icons/bi";
import { VscSymbolKeyword } from "react-icons/vsc";
import { MdOutlineBugReport } from "react-icons/md";
import { animated, useTransition } from "react-spring";

import styles from "./Sidebar.module.css";
import { Role } from "constants/global";
import { useAuthProvider } from "contexts/authenticationContext";

function MenuItem({ icon, path, title, setVisible, more }) {
  const location = useLocation();
  const history = useHistory();
  const isSelected = location.pathname.includes(path);

  const textClass = isSelected
    ? `${styles.text} ${styles.text_selected}`
    : styles.text;

  return (
    <div
      className={styles.menu_item_container}
      style={
        isSelected ? { color: "#fff", background: "rgb(27, 156, 252)" } : null
      }
      onClick={() => {
        history.push(path);
        setVisible(false);
      }}
    >
      {icon}
      <div>
        <span className={textClass}>{title}</span>
        {more && <span className={styles.more_text}>{more}</span>}
      </div>
    </div>
  );
}

function Sidebar({ visible, setVisible }) {
  const { t } = useTranslation();
  const { currentUser } = useAuthProvider();
  const transition = useTransition(visible, {
    enter: { left: "0rem" },
    from: { left: "-28rem" },
    leave: { left: "-28rem" },
  });

  const menus = [
    {
      path: "/dashboard",
      name: t("DASHBOARD"),
      icon: <BiLineChart className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA, Role.QC, Role.LEADER],
    },
    {
      path: "/calllog",
      name: t("CALL_LOG"),
      icon: <BiPhoneCall className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA, Role.QC, Role.LEADER],
    },
    {
      path: "/metric",
      name: t("METRIC"),
      icon: <MdOutlineSpaceDashboard className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA],
    },
    {
      path: "/tagging",
      name: t("AUTO_TAGGING"),
      icon: <BiPurchaseTagAlt className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA],
    },
    {
      path: "/term-discovery",
      name: t("TERM_DISCOVERY"),
      icon: <BiSearch className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA, Role.QC, Role.LEADER],
    },
    {
      path: "/agent-management",
      name: t("AGENT_MANAGEMENT"),
      icon: <MdOutlineSupportAgent className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA],
    },
    {
      path: "/dictionary",
      name: t("DICTIONARY"),
      icon: <BiBookAdd className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA],
    },
    {
      path: "/report",
      name: t("REPORT"),
      icon: <MdOutlineBugReport className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA, Role.QC, Role.LEADER],
    },
    {
      path: "/task",
      name: "Task",
      icon: <BiTask className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA],
    },
    {
      path: "/user",
      name: t("USER_MANAGEMENT"),
      icon: <BiUser className={styles.icon} />,
      roles: [Role.ADMIN, Role.QA],
    },
    // {
    //   path: "/keyword",
    //   name: t("KEYWORD_MANAGEMENT"),
    //   icon: <VscSymbolKeyword className={styles.icon} />,
    //   roles: [Role.ADMIN, Role.QA],
    // },
  ];

  return (
    <>
      {transition((style, item) => {
        if (item) {
          return (
            <animated.div className={styles.container} style={style}>
              <div
                className={styles.close_btn}
                onClick={() => {
                  setVisible(false);
                }}
              >
                <CloseOutlined />
              </div>
              {menus
                .filter((item) => item.roles.includes(currentUser.role))
                .map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      path={item.path}
                      title={item.name}
                      icon={item.icon}
                      setVisible={setVisible}
                      more={item.more}
                    />
                  );
                })}
            </animated.div>
          );
        }
        return "";
      })}
    </>
  );
}

export default Sidebar;
