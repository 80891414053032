import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  notes: [],
  teams: [],
  calls: [],
  total: 0,
  tags: [],
  filterTeamId: null,
  filterStartDate: null,
  filterEndDate: null,
  filterAgentName: null,
  isSortResultDesc: true,
  filterTags: [],
  filterPositiveMetrics: [],
  filterNegativeMetrics: [],
  filterStartScore: null,
  filterEndScore: null,
  filterUcid: null,
  filterDurationStart: null,
  filterDurationEnd: null,
  filterDirection: null,
  filterAgentId: null,
  filterPhone: null,
  filterFormId: null,
  filterScoringBy: null,
  filterScoringStartDate: null,
  filterScoringEndDate: null,
};
const callSlice = createSlice({
  name: "call",
  initialState: initialState,
  reducers: {
    setCalls: (state, action) => {
      const { calls, total } = action.payload;
      state.calls = calls;
      state.total = total;
    },
    removeCallById: (state, action) => {
      const callId = action.payload;
      const tmp = [...state.calls];
      const idx = tmp.findIndex((item) => item.call_id === callId);
      if (idx >= 0) {
        tmp.splice(idx, 1);
        state.calls = tmp;
        state.total -= 1;
      }
    },
    setCallById: (state, action) => {
      const { call_id, data } = action.payload;
      const tmp = [...state.calls];
      const idx = tmp.findIndex((item) => item.call_id === call_id);
      if (idx >= 0) {
        tmp[idx] = { ...tmp[idx], ...data };
        state.calls = tmp;
      }
    },
    setTags: (state, action) => {
      const tags = [...action.payload];
      state.tags = tags;
    },
    addTags: (state, action) => {
      const newTags = [...action.payload];
      const tmp = [...state.tags];
      const addTags = [];
      for (let i = 0; i < newTags.length; i++) {
        const tag = newTags[i];
        if (!tmp.includes(tag)) {
          addTags.push(tag);
        }
      }
      state.tags = [...tmp, ...addTags];
    },
    removeTags: (state, action) => {
      const rmTags = [...action.payload];
      // Update calls
      const tmpCalls = [...state.calls];
      const newCalls = tmpCalls.map((item) => {
        const newTags = item.tags.filter((tag) => !rmTags.includes(tag));
        item.tags = newTags;
        return item;
      });
      state.calls = newCalls;

      // Update tags
      const tmpTags = [...state.tags];
      const newTags = tmpTags.filter((tag) => !rmTags.includes(tag));
      state.tags = [...newTags];
    },
    addNote: (state, action) => {
      const notes = [...state.notes, action.payload];
      state.notes = notes;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    removeNote: (state, action) => {
      const notes = [...state.notes];
      const idx = notes.map((item) => item._id).indexOf(action.payload);
      if (idx >= 0) {
        notes.splice(idx, 1);
        state.notes = notes;
      }
    },
    setFilterValue: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setFilterDate: (state, action) => {
      const { start, end } = action.payload;
      if (start) {
        state.filterStartDate = moment(start).format("YYYY-MM-DD HH:mm:ss");
      } else {
        state.filterStartDate = null;
      }
      if (end) {
        state.filterEndDate = moment(end).format("YYYY-MM-DD HH:mm:ss");
      } else {
        state.filterEndDate = null;
      }
    },
    setFilterScoringDate: (state, action) => {
      const { start, end } = action.payload;
      if (start) {
        state.filterScoringStartDate = moment(start).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        state.filterScoringStartDate = null;
      }
      if (end) {
        state.filterScoringEndDate = moment(end).format("YYYY-MM-DD HH:mm:ss");
      } else {
        state.filterScoringEndDate = null;
      }
    },
  },
});

export const callAction = callSlice.actions;

export default callSlice.reducer;
