import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keywordList: [],
  total: 0,
};

const keywordSlice = createSlice({
  name: "keyword",
  initialState: initialState,
  reducers: {
    setKeywordList: (state, action) => {
      const { keywords, total } = action.payload;
      state.keywordList = keywords;
      state.total = total;
    },
  },
});

export const keywordAction = keywordSlice.actions;

export default keywordSlice.reducer;
