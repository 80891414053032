import "./App.css";

import Router from "routers";
import { AuthProvider } from "contexts/authenticationContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const lan = localStorage.getItem("i18n");
    if (!lan) {
      localStorage.setItem("i18n", "vi");
    } else {
      i18n.changeLanguage(lan);
    }
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <Router />
      </AuthProvider>
    </div>
  );
}

export default App;
