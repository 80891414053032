import { createContext, useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import authApi from "apis/authentication";
import { TOKEN_KEY, EXPIRED_KEY, USERNAME_KEY } from "constants/global";
import Loading from "components/ui/Loading";
import { showErrorNotification } from "components/ui/Notification";

const authContext = createContext();

export function useAuthProvider() {
  return useContext(authContext);
}

export function AuthProvider({ children }) {
  const history = useHistory();
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem(TOKEN_KEY);
    let expired = localStorage.getItem(EXPIRED_KEY);
    let username = localStorage.getItem(USERNAME_KEY);
    let timeout = null;
    let remainingTime = null;

    const getUser = async () => {
      try {
        setLoading(true);
        const data = await authApi.getUser();
        setLoading(false);
        return data;
      } catch (err) {
        logout();
        setLoading(false);
        return null;
      }
    };

    if (currentUser) {
      token = currentUser.token;
      expired = currentUser.expired;
      remainingTime = calcRemaningTime(expired);
    } else if (!currentUser && token && expired && username) {
      expired = parseInt(expired);
      getUser()
        .then((userData) => {
          const isValidToken = userData !== null;
          if (isValidToken) {
            const user = {
              username,
              token,
              expired,
              role: userData.role,
              id: userData.user_id,
              logo: userData.logo,
            };
            setCurrentUser(user);
            remainingTime = calcRemaningTime(expired);
          }
        })
        .catch((err) => {
          throw err;
        });
    } else {
      logout();
    }

    if (remainingTime !== null) {
      timeout = setTimeout(() => {
        logout();
      }, remainingTime);
    }
    checkValidUrl();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [currentUser]);

  const checkValidUrl = () => {
    const path = location.pathname;
    let validPaths = [];
    let token = localStorage.getItem(TOKEN_KEY);
    let expired = localStorage.getItem(EXPIRED_KEY);
    let defaultPage;
    if (currentUser || (!currentUser && token && expired)) {
      validPaths = [
        "/dashboard",
        "/metric",
        "/term-discovery",
        "/chatlog",
        "/evaluation",
        "/coaching",
        "/calllog",
        "/agent-management",
        "/dictionary",
        "/tagging",
        "/report",
        "/task",
        "/user",
        "/keyword",
      ];
      defaultPage = "/dashboard";

      // corner case
      if (path.includes("/call-detail/")) {
        return;
      }
    } else {
      validPaths = ["/login"];
      defaultPage = "/login";
    }

    if (!validPaths.includes(path)) {
      history.push(defaultPage);
    }
  };

  const calcRemaningTime = (expired) => {
    const now = new Date().getTime();
    const remaning = expired - now;
    if (remaning < 0) return 0;
    return remaning;
  };

  const login = async (username, password, complete) => {
    try {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(EXPIRED_KEY);
      localStorage.removeItem(USERNAME_KEY);
      const data = await authApi.login(username, password);
      if (data) {
        const { expire_in, token, role, logo } = data;
        const expired = new Date().getTime() + expire_in * 1000;
        setCurrentUser({
          username,
          token,
          expired,
          role,
          logo,
        });
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(EXPIRED_KEY, expired);
        localStorage.setItem(USERNAME_KEY, username);
        history.push("/dashboard");
        complete();
      }
    } catch (err) {
      complete();
      showErrorNotification(err.detail);
    }
  };

  const logout = () => {
    authApi
      .logout()
      .then((resp) => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(EXPIRED_KEY);
        localStorage.removeItem(USERNAME_KEY);
        setCurrentUser(null);
        history.push("/login");
      })
      .catch((err) => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(EXPIRED_KEY);
        localStorage.removeItem(USERNAME_KEY);
        setCurrentUser(null);
        history.push("/login");
      });
  };

  const value = {
    login,
    logout,
    currentUser,
    loading,
  };

  return (
    <authContext.Provider value={value}>
      {loading ? <Loading /> : <>{children}</>}
    </authContext.Provider>
  );
}
