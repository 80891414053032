import { createSlice } from "@reduxjs/toolkit";
import { SearchMode } from "constants/discovery";

const initialState = {
  selectedSpeaker: "agent",
  selectedTeam: "none",
  dates: null,
  search: null,
  selectedAgents: [],
  agents: [],
  sort: true,
  searchMode: SearchMode.TERM,
  total: 0,
  result: [],
  page: 1,
  pageSize: 20,
  loading: false,
  phraseList: [],
};

const discovertySlice = createSlice({
  name: "discovery",
  initialState: initialState,
  reducers: {
    changeSpeaker: (state, action) => {
      state.selectedSpeaker = action.payload.speaker;
    },
    changeTeam: (state, action) => {
      state.selectedTeam = action.payload.team;
    },
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeDate: (state, action) => {
      state.dates = action.payload;
    },
    selectAgents: (state, action) => {
      state.selectedAgents = action.payload.agents;
    },
    setAgents: (state, action) => {
      state.agents = action.payload;
    },
    changeSort: (state, action) => {
      state.sort = action.payload.sort;
    },
    changeSearchMode: (state, action) => {
      state.searchMode = action.payload;
    },
    setResult: (state, action) => {
      state.total = action.payload.total;
      state.result = action.payload.result;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPhraseList: (state, action) => {
      state.phraseList = action.payload;
    },
    addPhraseList: (state, action) => {
      const newList = [...state.phraseList, action.payload];
      state.phraseList = newList;
    },
    updatePhraseList: (state, action) => {
      const { id, phrases } = action.payload;
      const tmp = [...state.phraseList];
      const ids = tmp.map((item) => item._id);
      if (ids.length > 0) {
        const idx = ids.indexOf(id);
        if (idx >= 0) {
          tmp[idx].phrases = phrases;
          if (action.payload.hasOwnProperty("name")) {
            tmp[idx].name = action.payload.name;
          }
          if (action.payload.hasOwnProperty("description")) {
            tmp[idx].description = action.payload.description;
          }
          state.phraseList = tmp;
        }
      }
    },
    deletePhraseList: (state, action) => {
      const { id } = action.payload;
      const tmp = [...state.phraseList];
      const ids = tmp.map((item) => item._id);
      if (ids.length > 0) {
        const idx = ids.indexOf(id);
        if (idx >= 0) {
          tmp.splice(idx, 1);
          state.phraseList = tmp;
        }
      }
    },
  },
});

export const discoveryAction = discovertySlice.actions;

export default discovertySlice.reducer;
