export const TOKEN_KEY = "TOKEN";
export const EXPIRED_KEY = "EXPIRED";
export const USERNAME_KEY = "USERNAME";

export const Speakers = [
  {
    name: "Agent",
    value: "agent",
  },
  {
    name: "Customer",
    value: "customer",
  },
  {
    name: "Both",
    value: "both",
  },
];

export const Speaker = {
  AGENT: 0,
  CUSTOMER: 1,
};

export const Sentiment = {
  NEUTRAL: "neutral",
  NEGATIVE: "negative",
  POSITIVE: "positive",
};

export const DateFormat = "YYYY-MM-DD HH:mm:ss";

export const Role = {
  ADMIN: "admin",
  QA: "qa",
  QC: "qc",
  LEADER: "leader",
};
