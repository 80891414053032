import React from "react";
import { Route } from "react-router-dom";
import Loading from "components/ui/Loading";

const Dashboard = React.lazy(() => import("pages/Dashboard"));
const Metric = React.lazy(() => import("pages/Metrics"));
const TermDiscovery = React.lazy(() => import("pages/TermDiscovery"));
const CallDetail = React.lazy(() => import("pages/CallDetail"));
const CallLog = React.lazy(() => import("pages/CallLog"));
const AgentManagement = React.lazy(() => import("pages/AgentManagement"));
const Dictionary = React.lazy(() => import("pages/Dictionary"));
const Tagging = React.lazy(() => import("pages/Tagging"));
const Report = React.lazy(() => import("pages/Report"));
const Task = React.lazy(() => import("pages/Task"));
const User = React.lazy(() => import("pages/User"));
const KeywordManagement = React.lazy(() => import("pages/KeywordManagement"));

function QARouter() {
  return (
    <>
      <Route
        path="/dashboard"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Dashboard {...props} />
          </React.Suspense>
        )}
        exact
      />
      <Route
        path="/dictionary"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Dictionary {...props} />
          </React.Suspense>
        )}
        exact
      />
      <Route
        path="/agent-management"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <AgentManagement {...props} />
          </React.Suspense>
        )}
        exact
      />
      <Route
        path="/calllog"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <CallLog {...props} />
          </React.Suspense>
        )}
        exact
      />
      <Route
        path="/metric"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Metric {...props} />
          </React.Suspense>
        )}
        exact
      />
      <Route
        path="/term-discovery"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <TermDiscovery {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/call-detail/:id"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <CallDetail {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/tagging"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Tagging {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/report"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Report {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/task"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Task {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/user"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <User {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/keyword"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <KeywordManagement {...props} />
          </React.Suspense>
        )}
        exact
      />
    </>
  );
}

export default QARouter;
