import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metrics: {
    customer_satisfaction: [],
    process_checklist: [],
    customize: [],
  },
  forms: [],
  isEditForm: false,
  selectedFormId: null,
  formsList: [],
};

const metricSlice = createSlice({
  name: "metrics",
  initialState: initialState,
  reducers: {
    addNewForm: (state, action) => {
      const tmp = [...state.formsList, action.payload];
      state.formsList = tmp;
    },
    setFormItem: (state, action) => {
      const newItem = action.payload;
      const tmp = [...state.formsList];
      const idx = tmp.findIndex((item) => item._id === newItem._id);
      if (idx >= 0) {
        tmp[idx] = { ...tmp[idx], ...newItem };
        state.formsList = tmp;
      }
    },
    setFormList: (state, action) => {
      const { forms } = action.payload;
      state.formsList = forms;
    },
    removeFormListItem: (state, action) => {
      const _id = action.payload;
      const tmp = [...state.formsList];
      const idx = tmp.findIndex((item) => item._id === _id);
      if (idx >= 0) {
        tmp.splice(idx, 1);
        state.formsList = tmp;
        if (tmp.length > 0) {
          state.selectedFormId = tmp[0]._id;
        } else {
          state.selectedFormId = null;
        }
        state.isEditForm = false;
      }
    },
    setSelectedFormId: (state, action) => {
      const id = action.payload;
      state.selectedFormId = id;
    },
    updateAllMetric: (state, action) => {
      state.metrics = action.payload.metrics;
    },
    setMetrics: (state, action) => {
      state.metrics = action.payload;
    },
    addMetric: (state, action) => {
      const tmp = { ...state.metrics };
      const tag = action.payload.tag;
      const item = action.payload.metric;
      tmp[tag] = [...tmp[tag], item];
      state.metrics = tmp;
    },
    addItemToForm: (state, action) => {
      const tmp = [...state.forms];
      tmp.push(action.payload);
      state.forms = tmp;
      state.isEditForm = true;
    },
    setForm: (state, action) => {
      const tmp = [...action.payload];
      state.forms = tmp;
    },
    setFormById: (state, action) => {
      // Payload : {data}
      const tmp = [...state.forms];
      const data = { ...action.payload };
      const idx = tmp.findIndex((item) => item._id === data._id);
      if (idx >= 0) {
        tmp[idx] = data;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },

    setFormCustomerPhrase: (state, action) => {
      // Payload : {_id, idx, value}
      const tmp = [...state.forms];
      const { _id, idx, value } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0) {
        tmp[index].config.customer_phrases[idx] = value;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    removeFormCustomerPhrase: (state, action) => {
      // Payload : {_id, idx}
      const tmp = [...state.forms];
      const { _id, idx } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (
        index >= 0 &&
        idx >= 0 &&
        idx < tmp[index].config.customer_phrases.length
      ) {
        const tmpPhrases = tmp[index].config.customer_phrases;
        tmpPhrases.splice(idx, 1);
        tmp[index].config.customer_phrases = tmpPhrases;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    addFormCustomerPhrase: (state, action) => {
      // Payload : {_id}
      const tmp = [...state.forms];
      const { _id } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0) {
        const tmpPhrases = tmp[index].config.customer_phrases;
        tmpPhrases.push("");
        tmp[index].config.customer_phrases = tmpPhrases;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    setFormAgentPhrase: (state, action) => {
      // Payload : {_id, idx, value}
      const tmp = [...state.forms];
      const { _id, idx, value } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0) {
        tmp[index].config.agent_phrases[idx] = value;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    removeFormAgentPhrase: (state, action) => {
      // Payload : {_id, idx}
      const tmp = [...state.forms];
      const { _id, idx } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (
        index >= 0 &&
        idx >= 0 &&
        idx < tmp[index].config.agent_phrases.length
      ) {
        const tmpPhrases = tmp[index].config.agent_phrases;
        tmpPhrases.splice(idx, 1);
        tmp[index].config.agent_phrases = tmpPhrases;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    addFormAgentPhrase: (state, action) => {
      // Payload : {_id}
      const tmp = [...state.forms];
      const { _id } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0) {
        const tmpPhrases = tmp[index].config.agent_phrases;
        tmpPhrases.push("");
        tmp[index].config.agent_phrases = tmpPhrases;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    setFormPhrase: (state, action) => {
      // Payload : {_id, idx, value}
      const tmp = [...state.forms];
      const { _id, idx, value } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0) {
        tmp[index].config.phrases[idx] = value;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    removeFormPhrase: (state, action) => {
      // Payload : {_id, idx}
      const tmp = [...state.forms];
      const { _id, idx } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0 && idx >= 0 && idx < tmp[index].config.phrases.length) {
        const tmpPhrases = tmp[index].config.phrases;
        tmpPhrases.splice(idx, 1);
        tmp[index].config.phrases = tmpPhrases;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    addFormPhrase: (state, action) => {
      // Payload : {_id}
      const tmp = [...state.forms];
      const { _id } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0) {
        const tmpPhrases = tmp[index].config.phrases;
        tmpPhrases.push("");
        tmp[index].config.phrases = tmpPhrases;
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    removeMetricInForm: (state, action) => {
      // Payload : {_id}
      const tmp = [...state.forms];
      const { _id } = action.payload;
      const index = tmp.findIndex((item) => item._id === _id);
      if (index >= 0) {
        tmp.splice(index, 1);
        state.forms = tmp;
        state.isEditForm = true;
      }
    },
    setIsEditForm: (state, action) => {
      state.isEditForm = action.payload;
    },
    moveMetricUp: (state, action) => {
      const _id = action.payload;
      const tmpForms = [...state.forms];
      const currentIdx = tmpForms.findIndex((item) => item._id === _id);
      if (tmpForms.length > 1 && currentIdx >= 0) {
        let swapIdx = currentIdx - 1;
        if (swapIdx < 0) {
          swapIdx = tmpForms.length - 1;
        }
        const currentMetric = { ...tmpForms[currentIdx] };
        const swapMetric = { ...tmpForms[swapIdx] };
        tmpForms[currentIdx] = swapMetric;
        tmpForms[swapIdx] = currentMetric;
        state.forms = tmpForms;
        state.isEditForm = true;
      }
    },
    moveMetricDown: (state, action) => {
      const _id = action.payload;
      const tmpForms = [...state.forms];
      const currentIdx = tmpForms.findIndex((item) => item._id === _id);
      if (tmpForms.length > 1 && currentIdx >= 0) {
        let swapIdx = currentIdx + 1;
        if (swapIdx >= tmpForms.length) {
          swapIdx = 0;
        }
        const currentMetric = { ...tmpForms[currentIdx] };
        const swapMetric = { ...tmpForms[swapIdx] };
        tmpForms[currentIdx] = swapMetric;
        tmpForms[swapIdx] = currentMetric;
        state.forms = tmpForms;
        state.isEditForm = true;
      }
    },
  },
});

export const metricAction = metricSlice.actions;

export default metricSlice.reducer;
