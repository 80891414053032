import React, { useState } from "react";
import { Breadcrumb, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styles from "./style.module.css";

// components
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

function Layout({ children }) {
  const location = useLocation();
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const { t } = useTranslation();

  const renderBreadcrumb = () => {
    const routes = {
      "/dashboard": t("DASHBOARD"),
      "/metric": t("METRIC"),
      "/term-discovery": t("TERM_DISCOVERY"),
      "/calllog": t("CALL_LOG"),
      "/agent-management": t("AGENT_MANAGEMENT"),
      "/dictionary": t("DICTIONARY"),
      "/tagging": t("AUTO_TAGGING"),
      "/report": t("REPORT"),
      "/task": t("TASK"),
      "/user": t("USER_MANAGEMENT"),
      "/keyword": t("KEYWORD_MANAGEMENT"),
    };

    const listBreadcrumb = [
      <Breadcrumb.Item key="/dashboard">
        <Link to="/dashboard" className={styles.text_bread_crumb}>
          {t("HOME")}
        </Link>
      </Breadcrumb.Item>,
    ];

    if (Object.keys(routes).includes(location.pathname)) {
      listBreadcrumb.push(
        <Breadcrumb.Item key={location.pathname}>
          <Link to={location.pathname} className={styles.text_bread_crumb}>
            {routes[location.pathname]}
          </Link>
        </Breadcrumb.Item>
      );
    } else if (location.pathname.includes("/call-detail/")) {
      listBreadcrumb.push(
        <Breadcrumb.Item key="/calllog">
          <Link to="/calllog" className={styles.text_bread_crumb}>
            {t("CALL_LOG")}
          </Link>
        </Breadcrumb.Item>
      );
      const parts = location.pathname.split("/");
      listBreadcrumb.push(
        <Breadcrumb.Item key="/call-detail">
          <Link to={location.pathname} className={styles.text_bread_crumb}>
            {parts[parts.length - 1]}
          </Link>
        </Breadcrumb.Item>
      );
    }

    return <Breadcrumb>{listBreadcrumb}</Breadcrumb>;
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.main}>
        <div className={styles.main_top_container}>
          <Button
            size="small"
            type="text"
            icon={<MenuOutlined className={styles.menu_icon} />}
            onClick={() => {
              setVisibleSidebar(true);
            }}
          />
          {renderBreadcrumb()}
        </div>
        <div className={styles.content}>{children}</div>
      </div>

      {/* Sidebar */}
      <Sidebar visible={visibleSidebar} setVisible={setVisibleSidebar} />
      {visibleSidebar && (
        <div
          className={styles.wrapper}
          onClick={() => {
            setVisibleSidebar(false);
          }}
        ></div>
      )}
    </div>
  );
}

export default Layout;
