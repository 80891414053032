export const Theme = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
};

export const MetricType = {
  SEARCH: "search",
  SENTIMENT: "sentiment",
  STATISTIC: "stats",
  NAME_LOOP: "name_loop",
  EMOTION: "emotion",
  SPEAKING_RATE: "speaking_rate",
  SENTIMENT_PAIR: "sentiment_pair",
  EMOTION_PAIR: "emotion_pair",
  COUNT_SEARCH: "count_search",
  MULTI_SEARCH: "multi_search",
  KEYWORD_PAIR: "keyword_pair",
  PERSON: "person",
  CROSS_TALK: "cross_talk",
  AGENT_SILENCE: "agent_silence",
  AGENT_END_CALL: "agent_end_call",
  DEAD_AIR_RESPONSE: "dead_air_response",
  MAX_CUSTOMER_TURN: "max_customer_turn",
  EMOTION_END: "emotion_end",
};

export const CountType = {
  BINARY: "binary",
  RANGE: "range",
};

export const SpeakerType = {
  AGENT: "agent",
  CUSTOMER: "customer",
  BOTH: "both",
};

export const SearchRange = {
  ALL: "all",
  FIRST_N_S: "first",
  LAST_N_S: "last",
};

export const SearchType = {
  EXACT: "text",
  REGEX: "regex",
  PHRASE_LIST: "phrase_list",
  SIM: "similarity",
  SEMANTIC: "semantic",
};

export const MetricCatalog = {
  CUSTOMER_SATISFY: "customer_satisfaction",
  PROCESS_CHECKLIST: "process_checklist",
  CUSTOMIZE: "customize",
};

export const MetricColor = {
  search: "#3498db",
  sentiment: "#e74c3c",
  stats: "#34495e",
  name_loop: "#1e3799",
  emotion: "#e67e22",
  speaking_rate: "#7f8c8d",
  sentiment_pair: "#9b59b6",
  emotion_pair: "#b71540",
  count_search: "#2ecc71",
  multi_search: "#1abc9c",
  keyword_pair: "#82ccdd",
  person: "#e056fd",
  cross_talk: "#cd84f1",
  agent_silence: "#4b4b4b",
  agent_end_call: "#5f27cd",
  dead_air_response: "#fbc531",
  max_customer_turn: "#e84118",
  emotion_end: "#FC427B",
};
