export const TaggingType = {
  MANUAL: "manual",
  AUTO: "auto",
};

export const SearchType = {
  TEXT: "text",
  EMOTION_STATS: "emotion_stats",
  EMOTION_TREND: "emotion_trend",
};

export const EmotionStatsCondition = {
  CONTENT: "content",
  TONE: "tone",
  CONTENT_AND_TONE: "content_and_tone",
  CONTENT_OR_TONE: "content_or_tone",
};
