import React from "react";
import { Route } from "react-router-dom";
import Loading from "components/ui/Loading";

const Dashboard = React.lazy(() => import("pages/Dashboard"));
const TermDiscovery = React.lazy(() => import("pages/TermDiscovery"));
const CallDetail = React.lazy(() => import("pages/CallDetail"));
const CallLog = React.lazy(() => import("pages/CallLog"));
const Report = React.lazy(() => import("pages/Report"));

function QCRouter() {
  return (
    <>
      <Route
        path="/dashboard"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Dashboard {...props} />
          </React.Suspense>
        )}
        exact
      />
      <Route
        path="/calllog"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <CallLog {...props} />
          </React.Suspense>
        )}
        exact
      />
      <Route
        path="/term-discovery"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <TermDiscovery {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/call-detail/:id"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <CallDetail {...props} />
          </React.Suspense>
        )}
        exact
      />

      <Route
        path="/report"
        render={(props) => (
          <React.Suspense fallback={<Loading />}>
            <Report {...props} />
          </React.Suspense>
        )}
        exact
      />
    </>
  );
}

export default QCRouter;
