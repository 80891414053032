import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Popover, Avatar, Image } from "antd";
import { getEnv } from "env";
import UtilMenu from "./UtilMenu";

import styles from "./Header.module.css";

import { useAuthProvider } from "contexts/authenticationContext";

import Logo from "assets/images/logo.png";
import NoLog from "assets/images/no_logo.png";
import ChangePassword from "./ChangePassword";

function Header() {
  const [visibleChangePassword, setVisibleChangePassword] = useState(false);
  const [partnerLogo, setPartnerLogo] = useState(null);

  const { currentUser } = useAuthProvider();

  useEffect(() => {
    if (currentUser) {
      setPartnerLogo(currentUser.logo);
    }
  }, [currentUser]);

  return (
    <div className={styles.container}>
      <Link to="/dashboard">
        <div className={styles.logo_container}>
          <Image
            src={Logo}
            alt="Nami Logo"
            className={styles.logo}
            preview={false}
          />
          <span>NamiSense - Agent Scoring</span>
          {partnerLogo && (
            // add auth token to header of image url
            <Image
              preview={false}
              src={`${getEnv().API_URL}/tenants/logo?filepath=${partnerLogo}`}
              alt="Partner Logo"
              className={styles.logo}
              fallback={NoLog}
            />
          )}
        </div>
      </Link>

      <div className={styles.avatar}>
        <Popover
          content={
            <UtilMenu setVisibleChangePassword={setVisibleChangePassword} />
          }
          trigger="click"
          placement="bottomRight"
        >
          <Avatar
            size="small"
            style={{
              // backgroundColor: getColor(currentUser?.username),
              verticalAlign: "middle",
            }}
            gap={"1rem"}
          >
            {currentUser?.username[0]}
          </Avatar>
        </Popover>

        {visibleChangePassword && (
          <ChangePassword
            visible={visibleChangePassword}
            setVisible={setVisibleChangePassword}
          />
        )}
      </div>
    </div>
  );
}

export default Header;
