import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { useAuthProvider } from "contexts/authenticationContext";
import { Role } from "constants/global";

// components
import Loading from "components/ui/Loading";
import Layout from "components/layout/main";
import QARouter from "./QARouter";
import QCRouter from "./QCRouter";

const Login = React.lazy(() => import("pages/Login"));

function Router() {
  const { currentUser } = useAuthProvider();

  const renderLoggedInRouter = (role) => {
    if (role === Role.ADMIN || role === Role.QA) {
      return <QARouter />;
    } else if (role === Role.QC || role === Role.LEADER) {
      return <QCRouter />;
    } else {
      return <></>;
    }
  };

  return (
    <Switch>
      {currentUser ? (
        <Layout>{renderLoggedInRouter(currentUser.role)}</Layout>
      ) : (
        <>
          <Route path="/" exact>
            <Redirect to="/login"></Redirect>
          </Route>
          <Route
            path="/login"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Login {...props} />
              </React.Suspense>
            )}
            exact
          />
        </>
      )}
    </Switch>
  );
}

export default Router;
