import { createSlice } from "@reduxjs/toolkit";
import { MetricType, CountType, MetricCatalog } from "constants/metric";

const initialState = {
  name: null,
  description: null,
  type: MetricType.SEARCH,
  theme: 1,
  tag: MetricCatalog.CUSTOMER_SATISFY,
  count_type: CountType.BINARY,
  config: {},
};

const metriCreateSlice = createSlice({
  name: "metricCreate",
  initialState: initialState,
  reducers: {
    setValue: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetAll: (state, action) => {
      state.name = null;
      state.description = null;
      state.type = MetricType.SEARCH;
      state.theme = 1;
      state.tag = MetricCatalog.CUSTOMER_SATISFY;
      state.count_type = CountType.BINARY;
      state.config = {};
    },
    setAllValue: (state, action) => {
      const { name, type, theme, tag, count_type, config, description } =
        action.payload;

      state.name = name;
      if (description) {
        state.description = description;
      }
      state.type = type;
      state.theme = theme;
      state.tag = tag;
      state.count_type = count_type;
      state.config = config;
    },
    resetConfig: (state, action) => {
      state.config = {};
    },
  },
});

export const metricCreateAction = metriCreateSlice.actions;

export default metriCreateSlice.reducer;
