import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  startDate: moment()
    .subtract(1, "day")
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss"),
  endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  selectedTeam: null,
};

const hasValue = (value) => {
  return value !== null && value !== undefined && value !== "";
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    changeDate: (state, action) => {
      const { start, end } = action.payload;
      if (hasValue(start) && hasValue(end)) {
        state.startDate = start;
        state.endDate = end;
      } else {
        state.startDate = null;
        state.endDate = null;
      }
    },
    changeSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
  },
});

export const dashboardAction = dashboardSlice.actions;

export default dashboardSlice.reducer;
