import { createSlice } from "@reduxjs/toolkit";
import { SearchType, TaggingType } from "constants/tagging";

const initialState = {
  configList: [],
  totalConfig: 0,
  configFilter: {
    name: null,
    tags: [],
    speaker: null,
    searchType: null,
  },
  loading: false,
  name: null,
  speaker: "customer",
  tag: null,
  search_type: SearchType.TEXT,
  tagging_type: TaggingType.MANUAL,
  target_teams: [],
  config: {
    phrases: [],
    stats_condition: "content",
    emotionList: [
      {
        label: null,
        type: "content",
        score: null,
        count: null,
      },
    ],
    trend_condition: "pattern",
    min_start: null,
    min_end: null,
    negative_threshold: null,
    pattern: "hight_all",
  },
};

const tagSlice = createSlice({
  name: "tag",
  initialState: initialState,
  reducers: {
    setConfigList: (state, action) => {
      state.configList = action.payload.configs;
      state.totalConfig = action.payload.total;
    },
    setFilterTags: (state, action) => {
      state.configFilter.tags = action.payload;
    },
    setFilterName: (state, action) => {
      state.configFilter.name = action.payload;
    },
    setFilterSpeaker: (state, action) => {
      state.configFilter.speaker = action.payload;
    },
    setFilterSearchType: (state, action) => {
      state.configFilter.searchType = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setValue: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setAllValue: (state, action) => {
      const {
        name,
        tags,
        speaker,
        search_type,
        target_teams,
        config,
        tagging_type,
      } = action.payload;
      state.name = name;
      if (tags && tags.length) {
        state.tag = tags[0];
      }
      state.speaker = speaker;
      state.search_type = search_type;
      state.target_teams = target_teams;
      state.config = config;
      state.tagging_type = tagging_type;
    },
  },
});

export const tagAction = tagSlice.actions;

export default tagSlice.reducer;
